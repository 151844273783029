import { React, useEffect, useState } from "react";
import { FaEdit, FaChair } from "react-icons/fa";
import { MdMenuBook, MdPhotoLibrary } from "react-icons/md";
import { BiDetail, BiCategory } from "react-icons/bi";
import {  useLocation, useNavigate } from "react-router-dom";
const Restaurant_Edit = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [loginData, setLoginData] = useState(null);
  useEffect(() => {
    let data = JSON.parse(localStorage.getItem("user"));
    setLoginData(data);
  }, []);
  const addImagePage = () => {
    navigate("/restaurant-images", { state: location.state });
  };
  const addCategoryPage = () => {
    navigate("/category", { state: location.state });
  };
  const addSubCategoryPage = () => {
    navigate("/sub-category", { state: location.state });
  };
  const addMenuPage = () => {
    navigate("/menu", { state: location.state });
  };
  const addMenuImagePage = () => {
    navigate("/menu-images", { state: location.state });
  };
  const restaurantEditPage = () => {
    navigate("/restaurant-details", { state: location.state });
  };

  const globalCategories = () => {
    navigate("/add-update-global-category", { state: location.state });
  };

  const addOrEditRestaurantCategories = () => {
    navigate("/add-or-edit-restaurant-categories", { state: location.state });
  };

  const addTablePage = () => {
    navigate("/sitting", { state: location.state });
  };
  const addOfferPage = () => {
    navigate("/offer", { state: location.state });
  };
  const managerPage = () => {
    navigate("/create-manager", { state: location.state });
  };
  const slotPage = () => {
    navigate("/time-slots", { state: location.state });
  };
  return (
    <>
      <main id="main">
        <div className="content-wrapper">
          <div className="page-nav">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12">
                  <div className="page-header ms-2">
                    <h3 className="page-title">
                      <span className="page-title-icon bg-gradient-primary text-white me-2">
                        <span>
                          <FaEdit size="20" />
                        </span>
                      </span>
                      Restaurant Edit
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <section className="mt-5 restaurant-edit">
            <div className="row">
              {loginData?.role === "SUPER_ADMIN" && (
               
                  <div className="col-md-4 crPointer">
                    <div className="card">
                      <div
                        className="card-body d-flex justify-content-between"
                        onClick={restaurantEditPage}
                      >
                        <h5>
                          <span>
                            <BiDetail className="text-purple me-2" />
                          </span>
                          <span className="align-middle">
                            Restaurant Details
                          </span>
                        </h5>
                        <div>
                          <FaEdit size="20" />
                        </div>
                      </div>
                    </div>
                  </div>)}
                  {(loginData?.role === "OWNER" || loginData?.role === "SUPER_ADMIN" ) && (
                  <div className="col-md-4">
                    <div className="card">
                      <div
                        className="card-body d-flex justify-content-between"
                        onClick={managerPage}
                      >
                        <h5>
                          <span>
                            <BiCategory className="text-purple me-2" />
                          </span>
                          <span className="align-middle">Create Manager</span>
                        </h5>
                        <div>
                          <FaEdit size="20" />
                        </div>
                      </div>
                    </div>
                  </div>
                
              )}

              <div className="col-md-4 crPointer">
                <div className="card">
                  <div className="card-body d-flex justify-content-between">
                    <h5>
                      <span>
                        <MdPhotoLibrary className="text-purple me-2" />
                      </span>
                      <span className="align-middle">Restaurant Images</span>
                    </h5>
                    <div onClick={addImagePage}>
                      <FaEdit size="20" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 crPointer">
                <div className="card">
                  <div
                    className="card-body d-flex justify-content-between"
                    onClick={addCategoryPage}
                  >
                    <h5>
                      <span>
                        <BiCategory className="text-purple me-2" />
                      </span>
                      <span className="align-middle">Add Category</span>
                    </h5>
                    <div>
                      <FaEdit size="20" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 crPointer">
                <div className="card">
                  <div
                    className="card-body d-flex justify-content-between"
                    onClick={addSubCategoryPage}
                  >
                    <h5>
                      <span>
                        <BiCategory className="text-purple me-2" />
                      </span>
                      <span className="align-middle">Add Sub Category</span>
                    </h5>
                    <div>
                      <FaEdit size="20" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 crPointer">
                <div className="card">
                  <div
                    className="card-body d-flex justify-content-between"
                    onClick={addMenuPage}
                  >
                    <h5>
                      <span>
                        <MdMenuBook className="text-purple me-2" />
                      </span>
                      <span className="align-middle">Add Menu</span>
                    </h5>
                    <div>
                      <FaEdit size="20" />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-4 crPointer">
                <div className="card">
                  <div
                    className="card-body d-flex justify-content-between"
                    onClick={addMenuImagePage}
                  >
                    <h5>
                      <span>
                        <MdMenuBook className="text-purple me-2" />
                      </span>
                      <span className="align-middle">Menu Images</span>
                    </h5>
                    <div>
                      <FaEdit size="20" />
                    </div>
                  </div>
                </div>
              </div>
              {loginData?.role === "SUPER_ADMIN" && 
              <div className="col-md-4 crPointer">
                <div className="card">
                  <div
                    className="card-body d-flex justify-content-between"
                    onClick={addTablePage}
                  >
                    <h5>
                      <span>
                        <FaChair className="text-purple me-2" />
                      </span>
                      <span className="align-middle">Sitting</span>
                    </h5>
                    <FaEdit size="20" />
                  </div>
                </div>
              </div>}
              <div className="col-md-4 crPointer">
                <div className="card">
                  <div
                    className="card-body d-flex justify-content-between"
                    onClick={slotPage}
                  >
                    <h5>
                      <span>
                        <FaChair className="text-purple me-2" />
                      </span>
                      <span className="align-middle">Make Slots</span>
                    </h5>
                    <FaEdit size="20" />
                  </div>
                </div>
              </div>
              {loginData?.role === "SUPER_ADMIN" && <>
              <div className="col-md-4 crPointer">
                <div className="card">
                  <div
                    className="card-body d-flex justify-content-between"
                    onClick={globalCategories}
                  >
                    <h5>
                      <span>
                        <BiCategory className="text-purple me-2" />
                      </span>
                      <span className="align-middle">Global Categories</span>
                    </h5>
                    <div>
                      <FaEdit size="20" />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-4 crPointer">
                <div className="card">
                  <div
                    className="card-body d-flex justify-content-between"
                    onClick={addOrEditRestaurantCategories}
                  >
                    <h5>
                      <span>
                        <BiCategory className="text-purple me-2" />
                      </span>
                      <span className="align-middle">Restaurant Categories</span>
                    </h5>
                    <div>
                      <FaEdit size="20" />
                    </div>
                  </div>
                </div>
              </div>


              <div className="col-md-4 crPointer">
                <div className="card">
                  <div
                    className="card-body d-flex justify-content-between"
                    onClick={addOfferPage}
                  >
                    <h5>
                      <span>
                        <BiCategory className="text-purple me-2" />
                      </span>
                      <span className="align-middle">Add Offer</span>
                    </h5>
                    <div>
                      <FaEdit size="20" />
                    </div>
                  </div>
                </div>
              </div></>}
            </div>
          </section>
        </div>
      </main>
    </>
  );
};
export default Restaurant_Edit;
