import { React, useEffect, useState } from "react";
import { FaArrowCircleRight, FaTrash } from "react-icons/fa";
import { BiCategory } from "react-icons/bi";
import {  useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { api, baseUrl } from "../url";
import { toast } from "react-toastify";
import { addButton } from "../utils/custom-button";
import { Http } from "../http-dragon";
import Confirmation from "../components/confirmation";
import { errorToast, successToast } from "./logics/utils";
const Category = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [categoryName, setCategoryName] = useState("");
  const [categoryList, setCategoryList] = useState([]);
  const [isConfirmation, setIsConfirmation] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [confirmationMsg, setConfirmationMsg] =useState("Are you sure ? You want to delete.")
  useEffect(() => {
    if (location.state._id) {
      fetchCategory();
    } else {
      navigate("/restaurant-lists");
    }
  }, []);
  const fetchCategory = () => {
    Http.loader(true);
    axios
      .post(baseUrl + api.getCategories, { restaurantID: location.state._id })
      .then((res) => {
        setCategoryList(res.data?.categories);
        addButton(res.data?.categories);
        Http.loader(false);
        toast.success(res.data?.message);
      })
      .catch((error) => {
        Http.loader(false);
        setCategoryList([]);
        toast.error(error.response?.data?.message);
      });
    Http.loader(false);
  };
  const addCategory = () => {
    if (!categoryName) {
      toast.error("Enter Category Name..!");
      return;
    }
    Http.loader(true);
    axios
      .post(baseUrl + api.createCategory, {
        restaurantID: location.state?._id,
        category: categoryName,
      })
      .then((res) => {
        toast.success(res.data?.message);
        fetchCategory();
        setCategoryName("");
        Http.loader(false);
      })
      .catch((error) => {
        console.log("errror");
        Http.loader(false);
        toast.error(error.response?.data?.message);
      });
  };
  const goToAddSubCategory = () => {
    navigate("/sub-category", { state: { _id: location.state._id } });
  };

  const updateCategories = (id) => {
    setIsConfirmation(true);
    setDeleteId(id)
  };
  const deleteData = (categoryID) => {
    axios
      .post(baseUrl + api.deleteCategory, { categoryID })
      .then((res) => {
        if (res.data) {
          successToast(res);
          fetchCategory()
        } else {
          errorToast(res);
        }
      })
      .catch((error) => {
        errorToast(error);
      });
  };

  return (
    <>
      <main id="main">
        <div className="content-wrapper">
          <div className="page-nav">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12">
                  <div className="page-header ms-2">
                    <h3 className="page-title">
                      <span className="page-title-icon bg-gradient-primary text-white me-2">
                        <span>
                          <BiCategory size="20" />
                        </span>
                      </span>
                      Category
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <section className="my-5 Category">
            <div className="row justify-content-center">
              <div className="col-md-5 shadowc bg-white py-3 rounded">
                <div className="row text-center">
                  <div className="col-8">
                    <label>
                      <b>
                        Category <span className="text-danger">*</span>
                      </b>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={categoryName}
                      onChange={(e) => setCategoryName(e.target.value)}
                    />
                  </div>

                  <div
                    className="col-4 align-self-center pt-4 pe-2 crPointer"
                    onClick={addCategory}
                  >
                    <div class="bg-gradient-primary text-white p-2 custom-button">
                      Add +
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="px-4">
            <div className="row justify-content-end">
              <div className="col-4 text-end mb-3" onClick={goToAddSubCategory}>
                <div class="sub-btn">
                  Add Sub Category <FaArrowCircleRight />{" "}
                </div>
              </div>
              <div className="col-md-12 bg-white p-lg-5 p-2 table-shadow mb-5 rounded">
                <table className="table table-bordered">
                  <tr>
                    <th>Sr.no</th>
                    <th>Category</th>
                    <th>Delete</th>
                  </tr>
                  {categoryList?.map((el, i) => {
                    return (
                      <tr key={i}>
                        <th>{i + 1}.</th>
                        <td>{el.name}</td>
                        <td
                          className="text-danger"
                          onClick={() => updateCategories(el._id)}
                        >
                          <FaTrash size={20} className="p-0" />
                        </td>
                      </tr>
                    );
                  })}
                </table>
              </div>
            </div>
          </section>
        </div>
      </main>
      <Confirmation
        show={isConfirmation}
        setShow={setIsConfirmation}
        message={confirmationMsg}
        deleteData={deleteData}
        id={deleteId}
      />
    </>
  );
};
export default Category;
