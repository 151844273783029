import {  Route } from "react-router-dom";
import Login from "../pages/login";
const authRout = [
  "/restaurant-lists",
  "/create-manager",
  "/restaurant-images",
  "/category",
  "/sub-category",
  "/menu",
  "/menu-images",
  "/time-slots",
  "/restaurant-edit",
  "/orders",
  "/order-history",
  "/notifications",
  "/",
  "/revenue",
  "/transactions"
];

export const authenticate = (url, components) => {
  let data = JSON.parse(localStorage.getItem("user"));
  if (data) {
    if (data?.role === "SUPER_ADMIN") {
      return <Route element={components} path={url} />;
    } else if (data.role === "OWNER") {
      let isPermitted = authRout.includes(url);
      if (isPermitted) {
        return <Route element={components} path={url} />;
      } else {
        return <Route path="/login" element={<Login />} />;
      }
    } 
    else if (data.role === "MANAGER") {
      let isPermitted = authRout.includes(url);
      if (isPermitted) {
        return <Route element={components} path={url} />;
      }
    }
  } else {
    return <Route path="/login" element={<Login />} />;
  }
};
