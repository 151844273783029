import { React, useEffect, useState } from 'react';
import { FaEdit } from "react-icons/fa";
import { BiDetail } from "react-icons/bi";

import { MDBDataTable } from 'mdbreact';
import {  useNavigate } from 'react-router-dom';
import axios from 'axios';
import { api, baseUrl } from '../url';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { selectCreater } from '../utils/custom-button';
import { Http } from '../http-dragon';
import Confirmation from '../components/confirmation';
import FullImage from '../components/full-image';
import { BsCashStack } from "react-icons/bs";
import { dateWithTime } from '../utils/date-formater';
const Restaurant_List = () => {
  const navigate = useNavigate()
  const [ownerList, setOwnerList] = useState([])
  const [restaurantList, setRestaurantList] = useState([])
  const [isConfimation, setIsConfimation] = useState(false)
  const [confirmationMsg, setConfirmationMsg] = useState("")
  const [deleteId, setDeleteId] = useState({})
  const [isShowImage,setIsShowImage] = useState(false)
  const [imageUrl,setImageUrl] = useState("")
  const [loginData,setLoginData] = useState(null)
  const [deleteFunction,setDeleteFunction] = useState()
  const [name, setName] = useState("");
  const [restaurantsList, setRestaurantsList] = useState([]);
  const [restaurantValue, setRestaurantValue] = useState("");
  const data = {
    columns: [
      {
        label: 'Date',
        field: 'date',
        sort: 'asc',
        width: 150
      },
      {
        label: 'GST',
        field: 'gst',
        sort: 'asc',
        width: 150
      },
      {
        label: 'GST Proof',
        field: 'proofGST',
        sort: 'asc',
        width: 150
      },
      {
        label: 'Fssai',
        field: 'fssai',
        sort: 'asc',
        width: 150
      },
      {
        label: 'Fssai Proof',
        field: 'proofFSI',
        sort: 'asc',
        width: 150
      },
      {
        label: 'Table',
        field: 'tablesQuantity',
        sort: 'asc',
        width: 150
      },
      {
        label: 'Restaurant Name',
        field: 'name',
        sort: 'asc',
        width: 200
      },
      {
        label: 'Revenue',
        field: 'revenue',
        sort: 'asc',
        width: 200
      },

      {
        label: 'Edit',
        field: 'edit',
        sort: 'asc',
        width: 150
      },
      {
        label: 'Open',
        field: 'isOpenNow',
        sort: 'asc',
        width: 150
      },
      {
        label: 'Delete',
        field: 'delete',
        sort: 'asc',
        width: 150
      },

    ],
    rows: restaurantList
  };

  useEffect(() => {
    let data = JSON.parse(localStorage.getItem("user"))
    setLoginData(data)
    if(data?.role === "OWNER"){
      setLoginData(data)
      data.value = data.ownerid
      getRestaurent(data)
    }else if(data?.role === "SUPER_ADMIN"){
      fetchOwner()
    }
  }, [])

  const fetchOwner = async () => {
    Http.loader(true)
      axios.get(baseUrl + api.getOwners).then(res => {
        Http.loader(false)
        setOwnerList(res.data.owners)
        selectCreater(res.data.owners)
      }).catch (error=> {
      Http.loader(false)
      setOwnerList([])
      toast.error(error.response.data.msg)
    })
    
  }


  const getRestaurent = (e) => {
    Http.loader(true)
      axios.post(baseUrl + api.getRestaurantByOwner, { ownerID: e.value }).then(res => {
        setRestaurantList(res.data?.restaurants)
        //  selectCreater(res.data.owners)
        Http.loader(false)
        addImage(res.data?.restaurants)
        addButton(res.data?.restaurants)
        addDate(res.data?.restaurants)
  
    }).catch (error=> {
      Http.loader(false)   
      setRestaurantList([])
      
      toast.error(error.response.data.message) 
    })
  }
  const showImage = (url)=>{
      setImageUrl(url)
      setIsShowImage(true)
  }
  const addImage = (array)=>{
    array.forEach(element => {
      element.proofFSI = <div className="crPointer" onClick={() => showImage(element?.gstImages[0]?.url)}><img height={30} width={30} src={element?.gstImages[0]?.url} alt='no img'/></div>
      element.proofGST = <div className="crPointer" onClick={() => showImage(element?.fssaiImages[0]?.url)}><img height={30} width={30} src={element?.fssaiImages[0]?.url} alt='no img'/></div>
    });
  }
const activeDeActiveRestaurant =async (deleteId)=>{
  try {
    Http.loader(true)
    const res = await axios.post(baseUrl + api.activeOrDeActiveRestaurant, deleteId)
    Http.loader(false)
    getRestaurent({ value: deleteId?.owner })
    toast.success(res?.data?.message)
  } catch (error) {
    Http.loader(false)
    toast.error(error?.response?.data?.message)
  }
}
const openCloseRestaurant =async (deleteId)=>{
  try {
    Http.loader(true)
    const res = await axios.post(baseUrl + api.changeRestaurantOpenStatus, deleteId)
    Http.loader(false)
    getRestaurent({ value: deleteId?.owner })
    toast.success(res?.data?.message)
  } catch (error) {
    Http.loader(false)
    toast.error(error?.response?.data?.message)
  }
}
  const activeOrDeActive = async (_id, status, owner) => {
    console.log("owner",owner);
 
    setIsConfimation(true)
    setConfirmationMsg(`Are you sure you want to ${status ? "DeActive" : "Active"} ?`)
    setDeleteId({restaurantID:_id, status:!status, owner}) 
    setDeleteFunction(() => () => activeDeActiveRestaurant({restaurantID:_id, status:!status, owner}));
  }
  const openOrClose = async (_id, status, owner) => {
    setIsConfimation(true)
    setConfirmationMsg(`Are you sure you want to ${status ? "Close" : "Open"} ?`)
    setDeleteId({restaurantID:_id, status:!status}) 
    setDeleteFunction(() => () => openCloseRestaurant({restaurantID:_id, status:!status, owner}));
  }
  const addButton = (array) => {
    array.forEach(element => {
      element.edit = <div  className="btn btn-primary pt-0 pb-1" onClick={() => editOwner(element._id)}><FaEdit /></div>
      element.delete = <div className={`btn btn-${element?.active ? "danger" : "success"} py-1`} onClick={() => activeOrDeActive(element._id, element?.active, element?.owner)}> {element?.active ? "De-Activate" : "Activate"}</div>
      element.isOpenNow = <div className={`btn btn-${element?.openNow ? "danger" : "success"} py-1`} onClick={() => openOrClose(element._id, element?.openNow, element?.owner)}> {element?.openNow ? "Close" : "Open"}</div>
      element.revenue = <div className={`btn btn-success py-1`} onClick={() => showRevenue(element._id,element?.name)}><BsCashStack/></div>
    });
  }
  const fetchSingleRestaurant = (restaurantID) => {
    Http.loader(true)
    try {
      axios.post(baseUrl + api.getSingleRestaurant,restaurantID).then((res) => {
        setRestaurantList([res.data.restaurant]);
        addImage([res.data?.restaurant])
        addButton([res.data?.restaurant])
        addDate([res.data?.restaurant])
        Http.loader(false)
      })
    }
    catch (error) {
      Http.loader(false)
    }
    Http.loader(false)
  };
  const addDate = (array) => {
    array.forEach(element => {
      element.date = dateWithTime(element.createdAt)
          });
  }
  useEffect(() => {
    if (name) {
      const clear = setTimeout(() => {
        fetchRestaurant();
      }, 500);
      return () => clearTimeout(clear);
    }
  }, [name]);
  const fetchRestaurant = () => {
    Http.loader(true)
    try{
      axios
      .post(baseUrl + api.getRestaurantsByName, { search: name })
      .then((res) => {
        labelAndValueTransformer(res.data.data);
        Http.loader(false)
      });
    }catch(error){
      Http.loader(false)
    }
   
    Http.loader(false)
  };
  const labelAndValueTransformer = (array) => {
    const data = array.map((el) => {
      return {
        label: el.name,
        value:  el._id
      };
    });
    setRestaurantsList(data);
  }
  const dropdownChangeHandler = (e) => {
    setRestaurantValue(e);
    
    fetchSingleRestaurant({restaurantID:e.value});
  };
  const editOwner = (_id) => {
    navigate("/restaurant-edit", { state: { _id } })
  }
  const showRevenue = (_id,name) => {
    navigate("/revenue", { state: { _id,name } })
  }
  return (
    <>
      <main id="main">
        <div className="content-wrapper">
          <div className="page-nav">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12">
                  <div className="page-header ms-2">
                    <h3 className="page-title">
                      <span className="page-title-icon bg-gradient-primary text-white me-2">
                        <span>
                          <BiDetail size='20' />

                        </span>
                      </span>
                      Restaurant List
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {loginData?.role === "SUPER_ADMIN"?<><div className="row"> <div className="col-4 col-md-4 mt-4">
            <label htmlFor="applicant"><b>Select Owner</b></label>
            <Select
              options={ownerList}
              name='owner'
              onChange={getRestaurent}
            />
          </div><div className="col-1 col-md-1 mt-4">or</div><div className="col-4 col-md-4 mt-4">
            <label htmlFor="applicant"><b>Search by Restaurant name</b></label>
            <Select
             onInputChange={(e) => setName(e)}
             inputValue={name}
             options={restaurantsList}
             value={restaurantValue}
             onChange={dropdownChangeHandler}
            />
          </div></div> </>:""}
          
          <section className='px-2 mt-5'>

            <div className="row">
              <div className="col-md-12 bg-white p-lg-5 p-2 table-shadow mb-5 rounded">
                <MDBDataTable
                  data={data}
                  responsive
                />

              </div>
            </div>
          </section>
        </div>
      </main>
      <Confirmation show={isConfimation} setShow={setIsConfimation} message={confirmationMsg}deleteData={deleteFunction} id={deleteId}/>
      <FullImage show={isShowImage} setShow={setIsShowImage} data={imageUrl}/>
    </>
  );
}
export default Restaurant_List;