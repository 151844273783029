import React, { useEffect,useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { Http } from "../http-dragon";

import axios from "axios";
import { api, baseUrl } from "../url";
// import { FaUserPlus } from "react-icons/fa";
import { validateField } from "../utils/custom-validations";
import { FaTrash, FaUserPlus } from "react-icons/fa";
import { errorToast, successToast } from "./logics/utils";

const initialValue = {
    name: "",
    phoneNo: "",
    email: "",
    password:""
  }
const CreateManager = ()=>{
    const location = useLocation()
    const navigate = useNavigate()
    
    const [formData, setFormData] = useState(initialValue)
    const [formError, setFormError] = useState(initialValue)
    const [managerList, setManagerList] = useState([])

    useEffect(() => {
        if (location?.state) {
        fetchAllManager()
        } else {
          navigate("/restaurant-lists")
        }
      }, [])

      const fetchAllManager =async ()=>{
        const res = await axios.post(baseUrl + api.getAllManagers, {restaurantID:location.state._id})
        if(res.data){
           setManagerList(res.data.data)
        }else{
          setManagerList([])
        }
      }

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === "name" && value.length < 100) {
            setFormData({
              ...formData,
              [name]: value
            });
          }
       
         if (name === "phoneNo" && value.length < 11 && !isNaN(value)) {
          setFormData({
            ...formData,
            [name]: value
          });
        }
        else if ((name === "email" ) && value.length < 100) {
          setFormData({
            ...formData,
            [name]: value
          });
        }
        else if ((name === "password" || name === "password") && value.length < 100) {
          setFormData({
            ...formData,
            [name]: value
          });
        }
    
      };

      const ownerSubmit = async (e) => {
        e.preventDefault()
        const newErrors = {};
        Object.keys(formData).forEach((key) => {
          const error = validateField(key, formData[key]);
            if (error) {
              newErrors[key] = error;
            }
          
         
        });
        if (Object.keys(newErrors).length === 0) {
         
          Http.loader(true)
          try {
            formData["restaurant"] = location.state?._id
              const res = await axios.post(baseUrl + api.createManager, formData)
              successToast(res)
              Http.loader(false)
              setFormData(initialValue)
              setFormError(initialValue);
              fetchAllManager()
          } catch (error) {
            Http.loader(false)
            errorToast(error)
            setFormError(initialValue);
          }
        } else {
          Http.loader(false)
          setFormError(newErrors);
        }
      }

      const deleteManager =async (managerID)=>{
        const res =await axios.post(baseUrl+api.deleteManager,{managerID})
        if(res.data){
          successToast(res)
          fetchAllManager()
        }else{
          errorToast(res)
        }

      }


    return( <div>
                 <main id="main">
        <div className="content-wrapper">
          <div className="page-nav">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12">
                  <div className="page-header ms-2">
                    <h3 className="page-title">
                      <span className="page-title-icon bg-gradient-primary text-white me-2">
                        <span>
                          < FaUserPlus size='20' />
                        </span>
                      </span>
                      Create Manager
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <section className="owner px-4">
            <div>

              <form >
                <div className="row  mt-4 shadow-search">
                  <div className="col-12 col-md-4 ">
                    <label htmlFor="applicant"><b>Name</b></label>
                    <input type="text" name="name" className="form-control" onChange={handleChange} value={formData.name} />
                    <small className='text-danger'>{formError?.name}</small>
                  </div>
                  <div className="col-12 col-md-4 mt-lg-0 mt-3">
                    <label htmlFor="applicant"><b>Mobile Number</b></label>
                    <input type="text" name="phoneNo" className="form-control" onChange={handleChange} value={formData.phoneNo} />
                    <small className='text-danger'>{formError?.phoneNo}</small>
                  </div>
                  <div className="col-12 col-md-4 mt-lg-0 mt-3">
                    <label htmlFor="applicant"><b>Password</b></label>
                    <input type="text" name="password" value={formData.password} className="form-control" onChange={handleChange} />
                    <small className='text-danger'>{formError?.password}</small>
                  </div>

                  <div className="col-12 col-md-4  mt-3">
                    <label htmlFor="applicant"><b>Email Id</b></label>
                    <input type="text" value={formData.email} name="email" className="form-control" onChange={handleChange} />
                    <small className='text-danger'>{formError?.email}</small>
                  </div>
                
                  <div className="col-12 col-md-4 align-self-center mt-4 pt-2 text-center">
                    <button type='button'onClick={ownerSubmit} className="bg-gradient-primary text-white p-2 custom-button padding-btn ">{location?.state?.id ? "Update":"Submit"}</button>
                  </div>
                </div>
              </form>
            </div>
          </section>
          <section className="px-4">
            <div className="row  mt-4 shadow-search">
              <div className="col-12">
                <table className="table table-bordered">
                  <tr>
                    <th>Sr.no</th>
                    <th>Name</th>
                    <th>Phone</th>
                    <th>Email</th>
                    <th>Password</th>
                    <th>Action</th>
                  </tr>
                  {managerList.map((el, i) => {
                    return (
                      <tr key={el.name}>
                        <th>{i + 1}</th>
                        <td>{el.name}</td>
                        <td>{el.phoneNo}</td>
                        <td>{el.email}</td>
                        <td>{el?.visiblePassword}</td>
                        {/* <td>
                          <img src={el.url} className="img-fluid gimg"  height={90} width={90}/>
                        </td> */}
                        <td className="text-danger" onClick={()=>deleteManager(el._id)}><FaTrash size={20} className="p-0" /></td>
                      </tr>
                    );
                  })}
                </table>
              </div>
            </div>
          </section>
        </div>
      </main>
    </div>)
}
export default CreateManager