
import { io } from 'socket.io-client'
const socket = io("https://bhukkadadminbackend.bhukkads.in/", { autoConnect: false,transports:['websocket'] })

export const connectFromSocket = ({setWaiterNotification,getterOfWaiterNotification}) => {
  let token = localStorage.getItem("token")
  socket.connect()
  socket.on("connect", () => {
    console.log("socket is", socket.id);

    socket.emit("register", token)
    socket.on("CALL-THE-WAITER", (data) => {
      console.log(data,"waiter")
      setWaiterNotification([...getterOfWaiterNotification(),data])
    })

  })

}
