import { React, useEffect, useState } from "react";
import axios from "axios";
import { api, baseUrl } from "../url";
import { toast } from "react-toastify";
import { Http } from "../http-dragon";
import { BiCategory } from "react-icons/bi";
import { dateEasyFormat } from "../utils/date-formater";
import { useLocation, useNavigate } from "react-router-dom";

const Transactions = () => {
  const location  = useLocation()
  const navigate  = useNavigate()
  const [transactionList, setTransactionList] = useState([]);

  useEffect(() => {
    console.log("==",location.state);
    
    if(location.state){
      setTransactionList(location.state)
    }else{
      navigate("/restaurant-list")
    }
  }, []);

 

  // const fetchTransactionList = () => {
  //   Http.loader(true);
  //   axios
  //     .post(baseUrl + api.getPaymentsByRestaurant, { restaurantId:loca })
  //     .then((res) => {
  //       setTransactionList(res.data?.data);
  //       Http.loader(false);
  //     })
  //     .catch((error) => {
  //       Http.loader(false);
  //       setTransactionList([]);

  //       toast.error(error.response.data.message);
  //     });
  // };

 
 

  return (
    <>
      <main id="main">
        <div className="content-wrapper">
          <div className="page-nav">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12">
                  <div className="page-header ms-2">
                    <h3 className="page-title">
                      <span className="page-title-icon bg-gradient-primary text-white me-2">
                        <span>
                          <BiCategory size="20" />
                        </span>
                      </span>
                      Transations
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>

       

          <section className="px-4">
            <div className="row  mt-4 shadow-search">
              <div className="col-12">
                <table className="table table-bordered">
                  <tr>
                    <th>Sr.no</th>
                    <th>Date</th>
                    <th>Payment Date</th>
                    <th>Trnsaction Id</th>
                    <th>Owner Name</th>
                    <th>Restaurant Name</th>
                    <th>Amount</th>
                  </tr>
                  {transactionList?.map((el, i) => {
                    return (
                      <tr key={el.name}>
                        <td>{i + 1}</td>
                        <td>{dateEasyFormat(el.createdAt)}</td>
                        <td>{dateEasyFormat(el.transactionDate)}</td>
                        <td>{el.transactionId}</td>
                        <td>{el.ownerName}</td>
                        <td>{el.restaurantName}</td>
                        <td>{el.amount}</td>
                       
                      </tr>
                    );
                  })}
                </table>
              </div>
            </div>
          </section>
        </div>
      </main>
    </>
  );
};
export default Transactions;
