import { React, useEffect, useState } from "react";
import { FaHome } from "react-icons/fa";
import axios from "axios";
import { api, baseUrl } from "../url";
import { MDBDataTable } from "mdbreact";
import { toast } from "react-toastify";
import { http, Http } from "../http-dragon";
import { errorToast } from "./logics/utils";
import { getCurrentDate, getLast30DaysDate } from "../utils/date-formater";
import { useNavigate } from "react-router-dom";
import { BsCashStack } from "react-icons/bs";
const Dashboard = () => {
  const navigate = useNavigate();
  const preOrderCharge = 0.15
  const diningCharge = 0.03

  const [startDate, setStartDate] = useState(getLast30DaysDate());
  const [endDate, setEndDate] = useState(getCurrentDate());
  const [orderList, setOrderList] = useState([]);
  const [orderType, setOrderType] = useState("all");
  const [tempOrderType, setTempOrderType] = useState("all");
  const [totalData, setTotalData] = useState({});
  const [userData, setUserData] = useState({});

  useEffect(() => {
    let user = JSON.parse(localStorage.getItem("user"));
    setUserData(user);
    if (user?.role === "SUPER_ADMIN") {
      const reqBody = {
        startDate: getLast30DaysDate(),
        endDate: getCurrentDate(),
        orderType,
      };
      fetchOrderList(reqBody);
    }
  }, []);

  const fetchOrderList = (reqBody) => {
    Http.loader(true);
    axios
      .post(baseUrl + api.getTotalRevenue, reqBody)
      .then((res) => {
        Http.loader(false);
        console.log(res.data.data);
        setTotalData(res.data?.data[0]);

        addKeys(res.data?.data[0]?.restaurantsList);
      })
      .catch((err) => {
        setOrderList([]);
        http.loader(false);
        errorToast(err);
      });
  };

  const addKeys = (array) => {
    array.forEach((element) => {
      // element.showDetail = orderType==="DINING" ?
      element.finalAppRevenueKey = (
        <div
          className="btn btn-success "
          onClick={() => goToAppRevenue(element.restaurantId,element?.restaurantName)}
        >
          
          <BsCashStack className="fs-3" />
        </div>
      );
      element.totalRevenue2 = element?.totalRevenue?.toFixed(2);
      element.appTotalRevenue =(((element.takeawayTotal+element.preOrderTotal)*preOrderCharge)+(element?.dininOnlineTotal*diningCharge))?.toLocaleString('en-IN')
    });
    setTempOrderType(orderType);
    setOrderList(array);
  };
  const goToAppRevenue = (_id,name) => {
    navigate("/revenue", { state: { _id,name } });
  };

  const onSave = () => {
    if (!startDate?.trim() || !endDate || !orderType) {
      return toast.error("All fields required.");
    }
    const reqBody = {
      startDate,
      endDate,
      orderType,
    };
    fetchOrderList(reqBody);
  };
  const orderTypeHandler = (e) => {
    if (e.target.value) {
      setOrderType(e.target.value);
    }
  };

  const data = {
    columns: [
      {
        label: "Restaurant Name",
        field: "restaurantName",
        sort: "asc",
        width: 250,
      },
      {
        label: "Count",
        field: "count",
        sort: "asc",
        width: 150,
      },
      {
        label: "Discounted",
        field: "totalDiscount",
        sort: "asc",
        width: 150,
      },
      {
        label: "Order Value",
        field: "totalRevenue2",
        sort: "asc",
        width: 150,
      },
      {
        label: "Platform Fee",
        field: "appTotalRevenue",
        sort: "asc",
        width: 150,
      },
      {
        label: "Revenue Detail",
        field: "finalAppRevenueKey",
        sort: "asc",
        width: 150,
      },
    ],
    rows: orderList,
  };

  return (
    <>
      <main id="main">
        <div className="content-wrapper">
          <div className="page-nav">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12">
                  <div className="page-header ms-2">
                    <h3 className="page-title">
                      <span className="page-title-icon bg-gradient-primary text-white me-2">
                        <span>
                          <FaHome size="20" />
                        </span>
                      </span>
                      Dashboard ({userData?.role})
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
 {userData?.role === "MANAGER"&&
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
              <h4>Welcome on {userData?.restaurant[0]?.name}</h4>  
              </div>
            </div>
          </div>
}
          {userData?.role === "SUPER_ADMIN" && (
            <>
              <section className="owner px-4">
                <div>
                  <form>
                    <div className="row  mt-4 shadow-search">
                      <div className="col-12 col-md-4 ">
                        <label htmlFor="applicant">
                          <b>Start date</b>
                        </label>
                        <input
                          type="date"
                          name="startDate"
                          className="form-control"
                          value={startDate}
                          onChange={(e) => setStartDate(e.target.value)}
                        />
                      </div>

                      <div className="col-12 col-md-4">
                        <label htmlFor="applicant">
                          <b>End date</b>
                        </label>
                        <input
                          type="date"
                          name="endDate"
                          value={endDate}
                          className="form-control"
                          onChange={(e) => setEndDate(e.target.value)}
                        />
                      </div>
                      <div className="col-12 col-md-4 ">
                        <label htmlFor="applicant">
                          <b>Order Type</b>
                        </label>
                        <select
                          class="form-select"
                          aria-label="Default select example"
                          value={orderType}
                          onChange={orderTypeHandler}
                        >
                          <option value="">Select...</option>
                          <option value="DINING">Din-in</option>
                          <option value="PRE_ORDER">Pre Order</option>
                          <option value="TAKE_AWAY">Take AWay</option>
                          <option value="all">All</option>
                        </select>
                      </div>
                      <div className="col-12  mt-4  text-end">
                        <button
                          type="button"
                          disabled={!startDate.trim() || !endDate}
                          onClick={onSave}
                          className="bg-gradient-primary text-white p-2 custom-button padding-btn "
                        >
                          Check detail
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </section>
              {orderList?.length > 0 && (
                <section className="px-4">
                  <div className="row  mt-4 shadow-search">
                    <div className="col-12">
                      <table className="table table-bordered">
                        <tr className="bg-light">
                          <th>Order Count</th>
                          <th>Total discount</th>
                          <th>Total order value</th>
                          <th>Total Revenue</th>
                        </tr>
                        <tr>
                          <td>{totalData?.appOrderCount}</td>
                          <td>{totalData?.appTotalDiscount?.toFixed(2)}</td>
                          <td>{totalData?.appTotalRevenue?.toFixed(2)}</td>
                          <td>
                            {tempOrderType === "DINING" 
                              ? (totalData?.appTotalRevenue * 0.03)?.toFixed(2)
                              : (totalData?.appCartTotalValue * 0.15)?.toFixed(
                                  2
                                )}
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </section>
              )}
              <section className="px-4">
                <div className="row  mt-4 mb-5 shadow-search">
                  <MDBDataTable data={data} responsive />
                </div>
              </section>
            </>
          )}
        </div>
      </main>
    </>
  );
};
export default Dashboard;
